import React from 'react'
import {graphql} from 'gatsby'
import CenteredLayout from '../components/CenteredLayout/CenteredLayout'
import SEO from '../components/SEO/SEO'
// Styles
import '../styles/app.scss'

class Impressum extends React.Component {
  render() {
    const { data } = this.props
    const { markdownRemark } = data // data.markdownRemark holds our post data
    const { html } = markdownRemark
    return (
      <CenteredLayout location={this.props.location}>
        <SEO title="Impressum" />
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </CenteredLayout>
    )
  }
}

export default Impressum

export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
      html
    }
  }
`
